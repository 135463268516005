$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.cmp-utility-bar {
  padding: rem(8px 0);

  & > .cmp-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .utilitynav {
    display: none;

    @include media('>=desktop') {
      margin-left: auto;
      display: flex;
      justify-content: center;
    }
  }

  & .promotionbanner {
    background: none;
  }
}

.cmp-utility-bar:before {
  content: "";
  background-color: var(--sw-bg-fullbleed);
  @include fullbleed();
}

@include media('>=desktop') {
  .cmp-utility-bar {
    padding: 0;
  }
}

.cmp-utility-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cmp-utility-nav__link.cmp-button {
  display: flex;
  align-items: center;
  color: map-get($colors, dark-text);
  font-style: normal;
  font-weight: bold;
  font-size: rem(10px);
  letter-spacing: .02em;
  text-decoration: none;
  text-transform: uppercase;
  margin: rem(0 0 0 10px);
  padding-left: rem(15px);
  padding-right: rem(15px);
  // button overrides
  border-width: 0;
}

.cmp-utility-nav__icon {
  stroke: map-get($colors, dark-text);
  height: rem(14px);
  width: rem(25px);
}

[class*='cmp-utility-nav__link--icon']:before {
  display: inline-block;
  margin-right: rem(4px);
  content: '';
}

.cmp-utility-nav__link--icon-carat-right:before {
  @include icon-carat-right;
}

.cmp-utility-nav__link--icon-carat-left:before {
  @include icon-carat-left;
}

.cmp-utility-nav__link--icon-carat-down:before {
  @include icon-carat-down;
}

.cmp-utility-nav__link--icon-carat-up:before {
  @include icon-carat-up;
}

.cmp-utility-nav__link--icon-close:before {
  @include icon-close;
}

.cmp-utility-nav__link--icon-expand:before {
  @include icon-expand;
}

.cmp-utility-nav__link--icon-collapse:before {
  @include icon-collapse;
}

.cmp-utility-nav__link--icon-pindrop:before {
  @include icon-pindrop;
}

.cmp-utility-nav__link--icon-globe:before {
  @include icon-globe;
}

.cmp-utility-nav__link--icon-person:before {
  @include icon-person;
}

.cmp-utility-nav__link--icon-hotspot-circle:before {
  @include icon-hotspot-circle;
}

.cmp-utility-nav__link--icon-arrow-left:before {
  @include icon-arrow-left;
}

.cmp-utility-nav__link--icon-arrow-right:before {
  @include icon-arrow-right;
}

.cmp-utility-nav__link--icon-arrow-next:before {
  @include icon-arrow-next;
}

.cmp-utility-nav__link--icon-arrow-prev:before {
  @include icon-arrow-prev;
}

.cmp-utility-nav__link--icon-hamburger-menu:before {
  @include icon-hamburger-menu;
}

.cmp-utility-nav__icon--light {
  & .cmp-utility-nav__link.cmp-button {
    color: map-get($colors, light-text);
  }

  & .cmp-utility-nav__icon {
    stroke: map-get($colors, light-text);
  }
}

// UTILITY NAV in MEGA MENU on MOBILE
// - .cmp-nav__tab-item--utility is a wrapping class from "navigation" component
.cmp-nav__tab-item--utility {
  & .cmp-utility-nav {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }

  & .cmp-utility-nav__link.cmp-button {
    padding-left: rem(40px); // align with other clickable content on mobile
    margin-left: 0;
    margin-bottom: rem(40px);

    @include ui-overline1;
  }
}

.cmp-utility-nav__link.cmp-button:hover {
  border-width: 0;
  background-color: transparent;
  color: inherit;
}
